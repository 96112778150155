import ArticleContainer from "../ArticleContainer";

type Props = {
  bannerImage: string
  bannerImageMobile: string
}

const MalformacoesArticle = ({ bannerImage, bannerImageMobile }: Props): JSX.Element => (
  <ArticleContainer
    title="Malformações Renais Congênitas"
    highlight="correspondem a 20 a 30% de todas as anomalias identificadas no período pré-natal"
    bannerImage={bannerImage}
    bannerImageMobile={bannerImageMobile}
  >
    <h2>O que são?</h2>
    <p>Diferentemente das malformações adquiridas, que resultam do estilo de vida ou de causas ambientais, as malformações congênitas são alterações que estão presentes nas crianças já ao nascimento e que afetam a forma e a função dos rins e do trato urinário.  Elas são uma das causas mais comuns de doença renal crônica, de acordo com a Academia Americana de Pediatria (AAP).</p>
    <div className="row align-items-center">
      <div className="col-sm-9 col-md-10">
        <h2>Quais são os tipos?</h2>
        <p>Existem uma lista grande, mas vou exemplificar com algumas de acordo com a localização.</p>
        <h3>Nos rins:</h3>
        <ul>
          <li>Rim em ferradura: os rins podem se fundir, formando um único rim e se assemelhando a uma ferradura</li>
          <li>Doença renal policística: os rins apresentam cistos cheios de líquidos</li>
          <li>Agenesia renal: o bebê nasce com um rim</li>
          <li>Hipoplasia renal: o bebê nasce com um ou os dois rins anormalmente pequenos</li>
          <li>Displasia renal: um ou os dois rins não se formaram como deveriam</li>
        </ul>
        <h3>Nos ureteres:</h3>
        <ul>
          <li>Obstrução da junção ureteropélvica: quando ocorre um bloqueio na área onde o rim se liga ao ureter</li>
          <li>Obstrução ureterovesical: bloqueio na área onde o ureter se liga na uretra</li>
          <li>Refluxo vesicoureteral: os ureteres podem se prender à bexiga de forma a permitir que a urina flua de volta para o rim</li>
          <li>Duplicação pielocalicinal: presença de dois ureteres ao invés de um</li>
        </ul>
        <h3>Na uretra:</h3>
        <ul>
          <li>Válvula de uretra posterior: membrana de tecido pode se desenvolver na uretra, diminuindo ou bloqueando o fluxo de urina para fora da bexiga</li>
        </ul>
      </div>
      <div className="col hidden-on-mobile">
        <img src="/assets/image/icon-disease-malformacoes.svg" alt="Ícone malformações" />
      </div>
    </div>
    <p>Quando o fluxo de urina dos rins é bloqueado ou diminuído, ou quando a urina consegue retornar aos rins, ela pode se acumular e causar uma dilatação, condição conhecida como hidronefrose. Geralmente, esse é o primeiro sinal de um problema nos rins e no trato urinário, pois o rim dilatado pode ser visto em imagens de ultrassom pré-natal de rotina.</p>
    <h2>Quais as causas?</h2>
    <p>Ter uma história familiar de problemas renais ou do trato urinário pode aumentar o risco de um bebê nascer com uma dessas malformações, mas na maioria dos casos a causa é desconhecida.</p>
    <h2>Quais os sinais e sintomas?</h2>
    <p>Geralmente as malformações dos rins e do trato urinário são detectadas em um ultrassom pré-natal. Caso não seja detectado antes do nascimento, nem sempre causam sintomas específicos. Porém, as crianças podem apresentar como por exemplo infecções do trato urinário (às vezes notadas como febres inexplicáveis), barriga inchada (devido ao não esvaziamento da bexiga), náusea, vômito, dentre outros.</p>
    <h2>Como faz o diagnóstico?</h2>
    <p>Como dito acima, muitas vezes a malformação do rim ou do trato urinário é detectada antes do nascimento em um ultrassom de rotina, imagens pré-natais adicionais podem ser feitas para avaliar o problema com mais precisão e entender o risco para o bebê.</p>
    <p>Dependendo da gravidade do problema, conforme observado em estudos de imagem, exames adicionais são feitos dias ou semanas após o nascimento, como um novo ultrassom, uretrocistografia miccional (UCM) e cintilografia renal.</p>
    <p>Se o problema não for detectado ao nascimento, mas mais tarde na infância, exames adicionais podem ser realizados para entender a condição dos rins e avaliar a possível perda da função renal.</p>
    <h2>Qual o tratamento?</h2>
    <p>Muitas malformações congênitas do rim e trato urinário não tem cura, mas o Nefrologista Pediátrico irá ajudar a controlar os sintomas.</p>
    <p>Dependendo do tipo de alteração do seu filho, o tratamento pode incluir:</p>
    <ul>
      <li>Antibióticos para prevenir infecções do trato urinário</li>
      <li>Apenas observação, pois alguns problemas podem se resolver espontaneamente ou mesmo as crianças que tem apenas um rim podem ter uma vida saudável</li>
      <li>Cirurgia: alguns casos específicos necessitaram de abordagem cirúrgica</li>
    </ul>
    <p>Quando as malformações congênitas não são detectadas precocemente ou o tratamento não é capaz de prevenir danos aos rins, a criança pode evoluir com doença renal crônica (DRC) podendo necessitar de diálise e transplante renal.</p>
  </ArticleContainer>
)

export default MalformacoesArticle;
