import classNames from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface Segment {
    name: string,
    label: string
}

const SEGMENTS: Segment[] = [
    { name: 'hidronefrose', label: 'Hidronefrose' },
    { name: 'malformacoes', label: 'Malformações renais congênitas' },
    { name: 'infeccao', label: 'Infecção urinária' },
    { name: 'litiase', label: 'Litíase renal (pedra nos rins)' },
    { name: 'hipertensao', label: 'Hipertensão arterial' },
    { name: 'desfralde', label: 'Desfralde' },
    { name: 'enurese', label: 'Distúrbio miccional e enurese (xixi na cama)' },
    { name: 'glumerulopatias', label: 'Síndrome Nefrótica e Nefrítica' }
]

const AtuacaoArticle = (): JSX.Element => {
    return (
        <article id="atuacao" className={styles.article}>
            <div className={styles.arteCorte}>
                <img src="/assets/image/corte-grafismo-2.png" alt="Corte de grafismo" loading="lazy" />
            </div>
            <div className={styles.clinicIconWrapper}>
                <ScrollAnimation animateIn="fadeInLeft">
                    <div className="container">
                        <img src="/assets/image/icon-clinic.svg" alt="Ícone de clínica" loading="lazy" className={styles.clinicIcon} />
                    </div>
                </ScrollAnimation>
            </div>
            <div className="container">
                <ScrollAnimation animateIn="fadeInUp">
                    <h2 className={styles.title}>Atuação</h2>
                </ScrollAnimation>
                <div className={classNames('row', 'justify-content-center', styles.doencas)}>
                    {
                        SEGMENTS.map(({ name, label }, index) => {
                            return <div key={index} className="col-md-5 col-lg-4 col-xl-3">
                                <ScrollAnimation animateIn="zoomIn" duration={.5}>
                                    <Link to={`atuacao/${name}`} className={styles.botaoDoenca}>
                                        <span className={styles.botaoDoenca__wrapper}>
                                            <span className={classNames(styles[`icon_${name}`], styles.botaoDoenca__img)}><img src={`/assets/image/icon-disease-${name}.svg`} loading="lazy" alt={`Ícone ${label}`} /></span>
                                            <span className={styles.botaoDoenca__label}>{label}</span>
                                        </span>
                                    </Link>
                                </ScrollAnimation>
                            </div>
                        })
                    }
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <ScrollAnimation animateIn="fadeInUp">
                            <p className="text-center">Não encontrou a área que procura? Estas são apenas as principais de muitas áreas de atuação da Nefrologia Pediátrica.<br/> Fique de olho! Novos conteúdos poderão surgir posteriormente.<br/> <Link to="/#contato" className={styles.linkContato} style={{ whiteSpace: 'nowrap' }}>Entre em contato</Link> para saber mais ou sugerir assuntos.</p>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </article>
    );
}

export default AtuacaoArticle;
