import classNames from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import styles from './styles.module.scss';

interface Seal {
    title: string,
    image: string,
    backgroundWidth: string
}

const ACADEMIC: JSX.Element[] = [
    <><strong>Graduação em Medicina</strong> pela Universidade Federal do Espírito Santo (UFES) – Vitória/ES </>,
    <><strong>Residência Médica em Pediatria</strong> pelo Hospital Infantil Nossa Senhora da Glória (HEINSG) – Vitória/ES </>,
    <>Título de <strong>Especialista em Pediatria</strong> pela Sociedade Brasileira de Pediatria </>,
    <><strong>Residência Médica em Nefrologia Pediátrica</strong> pelo Instituto da Criança do Hospital das Clínicas da Faculdade de Medicina da Universidade de São Paulo (ICR – FMUSP) – São Paulo/SP</>,
    <><strong>Observership em Nefrologia Pediátrica</strong> pelo Cincinnati Children’s Hospital Medical Center – Cincinnati/Ohio </>,
    <>Título de <strong>Especialista em Nefrologia Pediátrica</strong> pela Sociedade Brasileira de Nefrologia e Sociedade Brasileira de Pediatria</>
]

const SEALS: Seal[] = [
    {
        title: 'Sociedade Brasileira de Pediatria',
        image: '/assets/image/selo-sbp.png',
        backgroundWidth: '55%'
    },
    {
        title: 'Sociedade Brasileira de Nefrologia',
        image: '/assets/image/selo-sbn.png',
        backgroundWidth: '80%'
    }
]

const RanielyArticle = (): JSX.Element => {

    return (
        <>
            <article id="dra-raniely" className={styles.articleRaniely}>
                <div className="container">
                    <div className="row align-items-end flex-row-reverse">
                        <div className="col-lg pb-5">
                            <ScrollAnimation animateIn="fadeInRight">
                                <h2 className={styles.title}>Drª Raniely</h2>
                            </ScrollAnimation>
                            <div className={styles.textWrapper}>
                                <ScrollAnimation animateIn="fadeIn">
                                    <img src="/assets/image/quotes.svg" loading="lazy" alt="Aspas" className={styles.aspas} />
                                </ScrollAnimation>
                                <div className={styles.textInner}>
                                    <ScrollAnimation animateIn="fadeInRight">
                                        <p>Olá, sou <strong>Raniely</strong>, mas meus pacientes me chamam de <strong>Tia Rani</strong>. Sempre fui apaixonada por crianças! Assim, durante o curso de Medicina, a <strong>Pediatria</strong> já era uma escolha acertada para a minha especialidade. Olhando para trás, agora, meu primeiro estágio na residência de Pediatria foi na enfermaria com casos de <strong>Nefrologia Pediátrica</strong>. Acho que foi o que chamamos de <strong>amor à primeira vista</strong>, né?</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="fadeInRight">
                                        <p>Com intuito de me tornar especialista nessa área, residi em São Paulo por dois anos e tive a oportunidade de viver experiências tanto em atendimento ambulatorial como hospitalar, tendo contato com doenças renais agudas e crônicas, congênitas e adquiridas, incluindo doenças renais complexas. Nesse período me apaixonei ainda mais por essa belíssima e tão desafiadora especialidade.</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="fadeInRight">
                                        <p>O fato é que, durante todos esses anos, sempre fui cercada por profissionais inspiradores. Aprendi, também como eles, que é preciso permanecer em constante evolução pessoal e profissional para levar com ética, empatia e humanidade o melhor cuidado para os pacientes.</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="fadeInRight">
                                        <p>
                                            <strong className="color-purple">Dra. Raniely Bullerjhan Schmidt</strong><br />
                                            Nefrologia Pediátrica<br />
                                            <span className="text-smaller">
                                                CRM-ES 13443<br />
                                                RQE Pediatria 11523<br />
                                                RQE Nefrologia Pediátrica 11524
                                            </span>
                                        </p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-5">
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src="/assets/image/dra-raniely.png" loading="lazy" alt="Drª Raniely" className={styles.fotoDraRani} />
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <div className={styles.corteGrafismo}>
                    <img src="/assets/image/corte-grafismo.png" loading="lazy" alt="Corte Grafismo" />
                </div>
            </article>
            <article className={styles.articleExp}>
                <div className={styles.pcIconWrapper}>
                    <ScrollAnimation animateIn="fadeInRight">
                        <div className="container">
                            <img src="/assets/image/icon-pc.svg" alt="Ícone de computador" loading="lazy" className={styles.pcIcon} />
                        </div>
                    </ScrollAnimation>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            {
                                ACADEMIC.map((item, index) => {
                                    return <ScrollAnimation animateIn="fadeInLeft" key={index}>
                                        <div className={styles.itemEspecializacoes}>
                                            <div>
                                                <img src="/assets/image/icon-certificate.svg" alt="Ícone de diploma" loading="lazy" />
                                            </div>
                                            <div>
                                                {item}
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                })
                            }
                        </div>
                        <div className="col-lg offset-md-1">
                            {
                                SEALS.map(({ title, image, backgroundWidth }, index) => {
                                    return <ScrollAnimation animateIn="fadeInRight" key={index}>
                                        <div className={classNames('row', 'align-items-center', styles.sociedades)}>
                                            <div className="col-4">
                                                <div className={styles.sociedades__image} style={{ backgroundImage: `url("${image}")`, backgroundSize: `${backgroundWidth} auto` }}></div>
                                            </div>
                                            <div className="col">
                                                <p>Membro da</p>
                                                <p className={styles.sociedades__title}>{title}</p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                })
                            }
                        </div>
                    </div>
                </div>
            </article>
        </>
    );
}

export default RanielyArticle;
