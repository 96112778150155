import ArticleContainer from "../ArticleContainer";

type Props = {
  bannerImage: string
  bannerImageMobile: string
}

const InfeccaoUrinariaArticle = ({ bannerImage, bannerImageMobile }: Props): JSX.Element => (
  <ArticleContainer
    title="Infecção urinária"
    highlight="uma das infecções bacterianas mais frequentes em Pediatria"
    bannerImage={bannerImage}
    bannerImageMobile={bannerImageMobile}
  >
    <div className="row align-items-center">
      <div className="col-sm-9 col-md-10">
        <p>O trato urinário normal é formado por dois rins, dois ureteres (canais que levam a urina dos rins para a bexiga), uma bexiga e uma uretra (canal que leva a urina para fora do corpo). Quando as bactérias entram na bexiga ou nos rins, pode ocorrer uma infecção do trato urinário (ITU).</p>
        <p>As infecções que atingem os rins são mais graves porque, se não forem tratadas rapidamente, podem causar danos permanentes aos rins e a longo prazo levar à pressão alta e doença renal crônica.</p>
        <h2>Quais as causas?</h2>
        <p>Nas crianças saudáveis, a maioria das infecções do trato urinário é causada pela bactéria Escherichia coli, normalmente encontrada nas fezes. Essa bactéria pode se mover do ânus para a uretra e para a bexiga (e às vezes para o rim), causando infecção.</p>
        <p>As ITUs são mais comuns em meninas porque a uretra delas é mais curta e mais próxima do ânus. Meninos não circuncidados com menos de 1 ano de idade também apresentam risco ligeiramente maior de infecção do trato urinário.</p>
        <p>Outros fatores de risco incluem:</p>
        <ul>
          <li>Malformação do trato urinário, como por exemplo refluxo vesicoureteral (um fluxo anormal de urina da bexiga até os ureteres e em direção aos rins)</li>
          <li>Mau funcionamento da bexiga urinária (disfunção miccional)</li>
          <li>Prisão de ventre (constipação)</li>
          <li>Hábitos de higiene inadequados</li>
        </ul>
      </div>
      <div className="col hidden-on-mobile">
        <img src="/assets/image/icon-disease-infeccao.svg" alt="Ícone infecção urinária" />
      </div>
    </div>
    <h2>Quais os sintomas?</h2>
    <p>Os sintomas de infecção do trato urinário dependem da idade da criança. Nas crianças com menos de dois anos de idade, os sinais e sintomas são inespecíficos, como febre, irritabilidade, vômitos, baixo ganho de peso, recusa alimentar. Já nas maiores de dois anos de idade, os sinais e sintomas são semelhantes ao dos adultos, como dor ou queimação para urinar, necessidade frequente para urinar, dor na parte inferior do abdome ou nas laterais das costas, febre.</p>
    <h2>Como diagnosticar?</h2>
    <p>Para determinar se a criança tem infecção do trato urinário, é preciso coletar uma amostra de urina.</p>
    <p>Nas crianças pequenas que ainda usam fralda, o teste inicial pode ser feito por saco coletor. Entretanto, se o resultado sugerir que a criança tenha ITU, é necessária uma nova coleta de amostra de urina por método mais confiável, devido a grande possibilidade contaminação da amostra quando essa é colhida por saco coletor.</p>
    <p>Nas crianças que não usam mais fraldas, a coleta de amostra de urina pode ser feita através de copo esterilizado, ou seja, por jato médio.</p>
    <h2>Qual tratamento?</h2>
    <p>O tratamento da infecção do trato urinário é feito através do uso de antibióticos, que dependerá da idade da criança, do germe que causou a ITU e da resistência que os germes têm. Para isso, o exame de cultura da urina é imprescindível tanto para confirmar o diagnóstico de ITU como para decidir o melhor tratamento a ser iniciado. Entretanto, o resultado da cultura não está disponível imediatamente e até lá o médico irá decidir qual antibiótico iniciar antes da conclusão do exame.</p>
    <p>Nas crianças muito pequenas ou naquelas que estiverem vomitando ou não aceitarem a medicação pela boca, pode ser necessário internação para tratamento com antibióticos intravenosos.</p>
    <h2>E depois do tratamento?</h2>
    <p>Toda criança, independentemente da idade e sexo, que tenha diagnóstico de certeza de infecção do trato urinário merece uma investigação com exame de imagem. Para isso, o acompanhamento com Nefrologista Pediátrico é essencial para melhor condução de cada caso e, dessa forma, evitar danos futuros ao trato urinário. </p>
  </ArticleContainer>
)

export default InfeccaoUrinariaArticle;
