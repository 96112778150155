import ErrorPage from 'pages/Error';
import { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import DesfraldeArticle from './Articles/Desfralde';
import EnureseArticle from './Articles/Enurese';
import GlumerulopatiasArticle from './Articles/Glumerulopatias';
import HidronefroseArticle from './Articles/Hidronefrose';
import HipertensaoArticle from './Articles/Hipertensao';
import InfeccaoUrinariaArticle from './Articles/InfeccaoUrinaria';
import LitiaseArticle from './Articles/LitiaseRenal';
import MalformacoesArticle from './Articles/Malformacoes';

const BANNER_OPTIONS = ['op1', 'op2', 'op3', 'op4', 'op5', 'op6', 'op7']

const generateRandom = () => Math.floor(Math.random() * (BANNER_OPTIONS.length))

const ArticlePage = (): JSX.Element => {
  const { articleName } = useParams() as any;

  const [bannerName, setBbannerName] = useState('')

  const banner = useMemo(() => {
    return `/assets/image/articles-covers/${bannerName}.jpg`
  }, [bannerName])
  const bannerMobile = useMemo(() => `/assets/image/articles-covers/${bannerName}-mobile.jpg`, [bannerName])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    window.scrollTo({ top: 0 })
    let newBanner = BANNER_OPTIONS[generateRandom()]
    while(newBanner === bannerName) {
      newBanner = BANNER_OPTIONS[generateRandom()]
    }
    setBbannerName(newBanner)
  }, [articleName])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <Switch>
      <Route exact path="/atuacao/hidronefrose" render={() => <HidronefroseArticle bannerImage={banner} bannerImageMobile={bannerMobile} />} />
      <Route exact path="/atuacao/malformacoes" render={() => <MalformacoesArticle bannerImage={banner} bannerImageMobile={bannerMobile} />} />
      <Route exact path="/atuacao/infeccao" render={() => <InfeccaoUrinariaArticle bannerImage={banner} bannerImageMobile={bannerMobile} />} />
      <Route exact path="/atuacao/litiase" render={() => <LitiaseArticle bannerImage={banner} bannerImageMobile={bannerMobile} />} />
      <Route exact path="/atuacao/hipertensao" render={() => <HipertensaoArticle bannerImage={banner} bannerImageMobile={bannerMobile} />} />
      <Route exact path="/atuacao/desfralde" render={() => <DesfraldeArticle bannerImage={banner} bannerImageMobile={bannerMobile} />} />
      <Route exact path="/atuacao/enurese" render={() => <EnureseArticle bannerImage={banner} bannerImageMobile={bannerMobile} />} />
      <Route exact path="/atuacao/glumerulopatias" render={() => <GlumerulopatiasArticle bannerImage={banner} bannerImageMobile={bannerMobile} />} />
      <Route render={(() => <ErrorPage status={404} />)} />
    </Switch>
  )
}

export default ArticlePage;
