import ArticleContainer from "../ArticleContainer";

type Props = {
  bannerImage: string
  bannerImageMobile: string
}

const HipertensaoArticle = ({ bannerImage, bannerImageMobile }: Props): JSX.Element => (
  <ArticleContainer
    title="Hipertensão arterial"
    highlight="a falta da medida da pressão nas crianças tem levado ao diagnóstico tardio"
    bannerImage={bannerImage}
    bannerImageMobile={bannerImageMobile}
  >
    <div className="row align-items-center">
      <div className="col-sm-9 col-md-10">
        <h2>O que é?</h2>
        <p>Popularmente conhecida como “pressão alta”, a hipertensão é a medida da pressão arterial maior que o normal em medições repetidas ao longo de um período. O número de casos no mundo todo vem subindo, principalmente por conta do aumento do sobrepeso e obesidade nessa faixa etária. É importante destacar que conforme seu filho cresce, a medida da pressão também aumenta, pois o valor normal da pressão varia conforme idade, sexo e estatura. </p>
        <h2>Como saber se meu filho tem pressão alta?</h2>
        <p>Na maioria dos casos, a pressão alta não dá sintomas. Algumas crianças e adolescentes podem ter dor de cabeça, alteração do sono e irritabilidade.</p>
        <p>Por isso, a melhor forma para o diagnóstico é a medida adequada da pressão arterial. A partir de 3 anos de idade, todas as crianças precisam ter sua pressão medida pelo menos uma vez ao ano. Antes dessa idade, apenas em situações especiais como: prematuridade, complicações no período neonatal, doenças cardíacas, doenças renais, dentre outras. Converse com o pediatra do seu filho.</p>
      </div>
      <div className="col hidden-on-mobile">
        <img src="/assets/image/icon-disease-hipertensao.svg" alt="Ícone hipertensão" />
      </div>
    </div>
    <h2>Por que crianças com hipertensão precisam consultar um Nefrologista Pediátrico?</h2>
    <p>A hipertensão em crianças costuma ser um sintoma de outra condição ou doença. Por isso, todas as crianças com hipertensão devem ser submetidas a uma avaliação para buscar a causa. A doença renal é a principal causa de pressão alta em crianças, razão pela qual os nefrologistas são os profissionais que avaliam e tratam essa condição em crianças.</p>
    <h2>Qual o tratamento?</h2>
    <p>O tratamento é direcionado para a causa da hipertensão. Assim, nos casos de excesso de peso, a recomendação é adotar um estilo de vida mais saudável com alimentação e atividade física regular. Além disso, em determinadas situações é necessário o uso de medicação para controle da pressão arterial.</p>
    <h2>Como evitar que meu filho tenha hipertensão?</h2>
    <p>Muitas vezes a “pressão alta” em crianças é secundária a uma doença ou condição subjacente e, dessa forma, a hipertensão só pode ser prevenida na medida em que a condição subjacente pode ser prevenida. Em outros casos, a hipertensão pode ser prevenida tendo um estilo de vida saudável, ou seja, alimentação com baixo teor de gordura e sódio, rica em frutas e vegetais, com atividade física regular, evitar fumar e manter um peso adequado.</p>
  </ArticleContainer>
)

export default HipertensaoArticle;
