import ArticleContainer from "../ArticleContainer";

type Props = {
  bannerImage: string
  bannerImageMobile: string
}

const GlumerulopatiasArticle = ({ bannerImage, bannerImageMobile }: Props): JSX.Element => (
  <ArticleContainer
    title="Síndrome Nefrótica e Nefrítica"
    highlight="a diferença vai muito além de uma letra"
    bannerImage={bannerImage}
    bannerImageMobile={bannerImageMobile}
  >
    <p>As doenças que acometem os glomérulos, uma estrutura complexa que tem a função de filtrar o sangue que chega ao rim, tem várias causas, mas clinicamente costumam se manifestar como duas grandes síndromes: Síndrome Nefrítica e Síndrome Nefrótica, que tem diferenças que vão muito além de apenas uma letra no nome.</p>
    <p>Aqui vai um breve resumo sobre cada uma delas...</p>
    <div className="row align-items-center">
      <div className="col-sm-8 col-md-9">
        <h2>Síndrome Nefrótica</h2>
        <p>É uma condição que faz com que os rins percam grandes quantidades de proteínas na urina. Embora possa afetar pessoas de qualquer idade, geralmente é diagnosticada pela primeira vez em crianças entre 2 e 5 anos de idade. Na maioria dos casos, a causa é desconhecida. Os sintomas incluem inchaço (notado inicialmente ao redor dos olhos, depois na parte inferior das pernas e resto do corpo) e alterações na urina (os altos níveis de proteína que passam para a urina podem fazer com que ela fique espumosa e a criança pode ter redução da quantidade de xixi). O diagnóstico se dará a partir de testes laboratoriais. O principal tratamento para a Síndrome Nefrótica são os esteróides. Crianças com diagnóstico pela primeira vez, normalmente recebem pelo menos um curso de 4 semanas do medicamento com redução progressiva a depender da resposta. A maioria das crianças responde bem ao tratamento, com a proteína desaparecendo da urina e o inchaço reduzindo, ou seja, entrando em remissão. Entretanto, podem ocorrer períodos de descompensação, não resposta ao tratamento com esteróides ou efeitos colaterais significativos, sendo necessário tratamento adicional. Durante o período de descompensação, é essencial cuidar da alimentação com redução da ingesta de sal para evitar mais retenção de água, piorando o inchaço.</p>
      </div>
      <div className="col hidden-on-mobile">
        <img src="/assets/image/icon-disease-glumerulopatias.svg" alt="Ícone hidronefrose" />
      </div>
    </div>
    <h2>Síndrome Nefrítica</h2>
    <p>A causa mais comum de Síndrome Nefrítica é aquela que se desenvolve cerca de 10 a 14 dias após uma infecção de pele ou garganta por uma bactéria específica, chamada Streptococcus. Ela não é causada pela própria bactéria em si, mas sim pelo sistema de combate a infecções. Dessa forma, o sistema imunológico produz anticorpos que ficarão presos nos glomérulos, os filtros dos rins. Isso gera uma inflamação local, dificultando a produção de urina e a eliminação dos resíduos. O diagnóstico se baseia nos sinais, sintomas e testes laboratoriais que identificarão inchaço, perda de sangue na urina, pressão alta, entre outros. O tratamento se concentra no alívio dos sintomas e na prevenção de complicações. Seu filho precisará de uma ingestão restrita de sal e talvez seja necessário restringir a quantidade de líquidos que ingere. Além disso, pode precisar de remédios para baixar a pressão arterial e/ou para estimular os rins a se livrar da água. A boa notícia é que 95% das crianças se recuperam totalmente em poucas semanas, sem complicações a longo prazo. O sangue que você pode ver no xixi geralmente desaparece em 2 semanas e a pressão alta diminui em cerca de 4 semanas. Os exames de urina ainda podem mostrar sangue na urina de seu filho por até 2 anos.</p>
    <p>Em ambas situações, o acompanhamento médico é fundamental.</p>
  </ArticleContainer>
)

export default GlumerulopatiasArticle;
