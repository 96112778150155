
export const scrollToArticle = (targetId: string): void => {
    const target = document.getElementById(targetId);
    if (!target) { return; }
    const targetOffset = target?.offsetTop || 0;
    const discount = (document.getElementById('headerComponent')?.clientHeight || 0) / 2;
    window.scroll({
        top: targetOffset - discount,
        behavior: 'smooth'
    });
}