import classNames from 'classnames';
import IconComponent from 'components/Icon';
import { menuConst, MenuItem } from 'consts/menu.const';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface MenuItemProps {
    item: MenuItem
    onClick: () => void
}

const MenuItemComponent = ({ item, onClick }: MenuItemProps): JSX.Element => {
    return <li>
        <Link to={item.link} onClick={onClick}>{item.title}</Link>
        {(item.children?.length || 0) > 0 &&
            <ul>
                {item.children?.map((subitem, index) => <MenuItemComponent key={`submenu-item-${index + 1}`} onClick={onClick} item={subitem} />)}
            </ul>
        }
    </li>
}

const HeaderComponent = (): JSX.Element => {

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <header id="headerComponent" className={classNames(styles.headerComponent, {[styles.menuOpen]: menuOpen})} style={{ backgroundImage: 'url("/assets/image/bg-header.png")' }}>
            <div className="container">
                <div className={classNames('row', styles.headerRow)}>
                    <div className="col-6 col-md-2">
                        <Link to="/#cover" className={styles.logo}>
                            <img src="/assets/image/logo-footer.svg" alt="Logo Raniely horizontal" className={styles.logoMobile} />
                            <img src="/assets/image/logo-header.svg" alt="Logo Raniely" className={styles.logoDesktop} />
                        </Link>
                    </div>
                    <div className="col offset-md-1 offset-lg-2 text-right">
                        <button type="button" className={styles.openMenu} onClick={() => setMenuOpen(true)}><IconComponent icon="menu" /></button>
                        <nav style={{ backgroundImage: 'url("/assets/image/art-rocket.svg")' }}>
                            <button type="button" className={styles.closeMenu} onClick={() => setMenuOpen(false)}><IconComponent icon="cancel" /></button>
                            <ul>
                                <li className={styles.mobileOnly}><a href="/#cover">Início</a></li>
                                {menuConst.map((item, index) => <MenuItemComponent key={`menu-item-${index + 1}`} onClick={() => setMenuOpen(false)} item={item} />)}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderComponent;
