import classNames from 'classnames';
import IconComponent from 'components/Icon';
import styles from './styles.module.scss';

const FooterComponent = (): JSX.Element => {

    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className={classNames('row', 'align-items-center', 'flex-row-reverse', styles.footerRow)}>
                    <div className="col-sm">
                        <a rel="noreferrer" href="https://wa.me/5527996207901" target="_blank" className={styles.btnSocial}><IconComponent icon="whatsapp" /></a>
                        <a rel="noreferrer" href="https://instagram.com/drarani.nefroped/" target="_blank" className={styles.btnSocial}><IconComponent icon="instagram" /></a>
                        <a rel="noreferrer" href="https://www.linkedin.com/in/ranibschmidt/" target="_blank" className={styles.btnSocial}><IconComponent icon="linkedin-squared" /></a>
                        <a rel="noreferrer" href="https://twitter.com/draraninefroped" target="_blank" className={styles.btnSocial}><IconComponent icon="twitter-squared" /></a>
                        <a rel="noreferrer" href="https://www.youtube.com/channel/UCqiWXFxAfD7lSfqv0M2QFjQ" target="_blank" className={styles.btnSocial}><IconComponent icon="youtube-play" /></a>
                    </div>
                    <div className="col-sm">
                        <img src="/assets/image/logo-footer.svg" alt="Logo Raniely horizontal" className={styles.logoFooter} />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;
