import ArticleContainer from "../ArticleContainer";

type Props = {
  bannerImage: string
  bannerImageMobile: string
}

const EnureseArticle = ({ bannerImage, bannerImageMobile }: Props): JSX.Element => (
  <ArticleContainer
    title="Distúrbios miccionais e enurese"
    highlight="podem acometer as crianças em todas as idades, embora fiquem mais evidentes após a retirada das fraldas, até a adolescência"
    bannerImage={bannerImage}
    bannerImageMobile={bannerImageMobile}
  >
    <div className="row align-items-center">
      <div className="col-sm-8 col-md-9">
        <h2>O que são?</h2>
        <p>Fazer xixi compreende uma ação coordenada entre a bexiga, a uretra e a musculatura do períneo sob o controle neurológico. Quando um dos componentes deste processo não funciona adequadamente, surge uma falta de coordenação que irá resultar em um distúrbio da micção. Dessa forma, os distúrbios miccionais ou disfunção do trato urinário inferior correspondem às diversas alterações da micção na infância.</p>
        <p>A enurese é a eliminação involuntária de urina durante o sono em crianças com idade igual ou superior a cinco anos. Crianças com disfunções do trato urinário inferior poderão apresentar a enurese como um sintoma do distúrbio miccional, e nestes casos, outras queixas estarão associadas. No entanto, a enurese poderá manifestar-se isoladamente, sem outros sintomas paralelos.</p>
      </div>
      <div className="col hidden-on-mobile">
        <img src="/assets/image/icon-disease-enurese.svg" alt="Ícone enurese" />
      </div>
    </div>
    <h2>Quais são as disfunções mais comuns?</h2>
    <p>De forma geral, os principais distúrbios da micção em crianças podem ser agrupados em três tipos:</p>
    <ul>
      <li>Bexiga hiperativa: o principal sintoma é um aumento da frequência da miccção, com episódios de urgência miccional, baixos volumes de xixi e, em alguns pacientes, enurese.</li>
      <li>Micção disfuncional: o músculo da bexiga pode estar hiperestimulado e a contração da bexiga não é seguida por um relaxamento da musculatura do períneo. Então surge a micção interrompida ou micção incompleta, com resíduo de xixi elevado dentro da bexiga, que pode provocar infecção urinária.</li>
      <li>Postergação da micção: a frequência miccional é muito reduzida, a criança passa intervalos longos de tempo sem apresentar micção e sem ter a sensação da bexiga cheia.</li>
    </ul>
    <p>Em relação a enurese, ela pode ser:</p>
    <ul>
      <li>Primária: quando a criança nunca conseguiu controle noturno da urina por mais de seis meses.</li>
      <li>Secundária: quando já apresentou controle noturno por ao menos seis meses consecutivos e voltou a perder urina durante o sono. Neste caso, frequentemente, há um fator predisponente.</li>
      <li>Monossintomática: sem outros sintomas associados durante o dia, apenas perda de urina durante o sono.</li>
      <li>Não monossintomática: com a presença de sintomas diurnos como, urgência para ir ao banheiro, escape de urina, frequência aumentada ou diminuída das micções ou alterações do jato urinário.</li>
    </ul>
    <h2>Qual o tratamento?</h2>
    <p>O tratamento vai variar de acordo com o tipo de disfunção, podendo ser uma orientação miccional associada ou não ao uso de medicações específicas.</p>
    <p>Algumas orientações gerais podem ajudar no controle da enurese:</p>
    <ul>
      <li>Incentivar a criança a fazer xixi a cada 2 a 3 horas</li>
      <li>Ingerir mais líquidos durante o dia, o que reduz a sede à noite</li>
      <li>Evitar alimentos com cafeína, cítricos e ricos em sal à noite</li>
      <li>Evitar ingestão de líquidos 2 horas antes de dormir</li>
      <li>Fazer xixi antes de dormir</li>
    </ul>
    <h2>Quando procurar um Nefrologista Pediátrico?</h2>
    <p>Em caso de alterações na eliminação da urina, é importante que os pais relatem ao pediatra que faz o acompanhamento regular da criança. Após uma avaliação inicial, ele poderá encaminhar ao Nefrologista Pediátrico a fim de uma investigação mais detalhada e tratamento adequado.</p>
    <p>A orientação médica é sempre recomendada para a diagnóstico correto e escolha do melhor tratamento para cada caso.</p>
  </ArticleContainer>
)

export default EnureseArticle;
