import ReactDOM from 'react-dom';
import BtnAgendamentoComponent from './components/BtnAgendamento';
import FooterComponent from './components/Footer';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import './scss/main.scss';

ReactDOM.render(
  <>
    <div className="main-container">
      <Routes />
    </div>
    <FooterComponent />
    <BtnAgendamentoComponent />
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
