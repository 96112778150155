import ArticleContainer from "../ArticleContainer";

type Props = {
  bannerImage: string
  bannerImageMobile: string
}

const DesfraldeArticle = ({ bannerImage, bannerImageMobile }: Props): JSX.Element => (
  <ArticleContainer
    title="Desfralde"
    highlight="cada criança tem seu ritmo e o sucesso depende da família toda"
    bannerImage={bannerImage}
    bannerImageMobile={bannerImageMobile}
  >
    <div className="row align-items-center">
      <div className="col-sm-9 col-md-10">
        <p>Tirar a fralda da criança é um marco importante no desenvolvimento e é preciso reconhecer o momento ideal para iniciar esse processo. Na verdade, o mais correto é se dizer que a fralda é deixada e não tirada.</p>
        <h2>Quando começar?</h2>
        <p>Muitos pais se baseiam na idade do seu filho para iniciar o processo do desfralde, mas na verdade não é a idade cronológica da criança em si que vai ditar o início e sim os marcos fisiológicos, de desenvolvimento e comportamento.</p>
        <h2>E quais são esses marcos?</h2>
        <ul>
          <li>A criança consegue puxar as roupas para cima e para baixo</li>
          <li>Entende instruções e comandos simples, como: “pega a chupeta no chão”</li>
          <li>Usa palavras, expressões faciais ou movimentos (como abaixar ou procurar um cantinho) que indicam necessidade de fazer xixi ou cocô</li>
          <li>Se sente incomodada por estar molhada de xixi ou suja de cocô e começa a verbalizar isso</li>
          <li>Mostra curiosidade ou interesse ao ver os pais ou cuidadores realizarem hábitos de higiene</li>
          <li>Fica seca por mais de 2 horas</li>
          <li>Consegue ficar parada no penico ou redutor de assento por alguns minutos</li>
          <li>E outros...</li>
        </ul>
      </div>
      <div className="col hidden-on-mobile">
        <img src="/assets/image/icon-disease-desfralde.svg" alt="Ícone Desfralde" />
      </div>
    </div>
    <p><strong>Nem todos esses sinais precisam estar presentes</strong> quando seu filho estiver pronto. Esse processo ocorre normalmente entre 2 e 3 anos de idade, mas tudo bem se ocorrer um pouco depois também. O desfralde não é uma competição!</p>
    <h2>Penico ou vaso sanitário?</h2>
    <p>Seu filho pode gostar mais de um ou de outro. Ou você pode incentivá-lo a usar os dois. Um penico tem como vantagem a facilidade de transporte e algumas crianças o consideram menos assustador. No caso do vaso sanitário, seu filho irá precisar de um degrau ou banquinho e um assento redutor que se encaixa com segurança, isso trará conforto e reduzirá o medo que ele pode ter de cair.</p>
    <h2>Como preparar meu filho para o desfralde?</h2>
    <p>O início do desfralde precisa ser orientado pela motivação, interesse e aquisição de habilidades da criança. É preciso que pelo menos uma pessoa seja capaz de devotar tempo e energia emocional para ser consistente no treinamento. Aqui vai algumas dicas:</p>
    <ul>
      <li>Ensine seu filho algumas palavras para ir ao banheiro - por exemplo, 'xixi', 'cocô'</li>
      <li>Ao trocar a fralda do seu filho, coloque fraldas molhadas e sujas no penico - isso pode ajudar seu filho a entender para que serve o penico</li>
      <li>Fale sobre o desfralde com a criança, usando livros ou vídeos</li>
      <li>Deixe seu filho observar você ou outro membro da família de confiança usando o banheiro e fale sobre o que você está fazendo</li>
      <li>Certifique-se de que seu filho está comendo muitas fibras e bebendo muita água, para que ele não fique constipado. A prisão de ventre pode tornar o treinamento do banheiro mais difícil</li>
    </ul>
    <h2>Como lidar com os acidentes?</h2>
    <p>Os pais precisam compreender que os escapes são inevitáveis e que a punição não tem nenhum papel durante o processo do desfralde. Acidentes e contratempos fazem parte do treinamento para usar o banheiro, principalmente em momentos de estresse como por exemplo na chegada de um irmão.</p>
    <p>As crianças podem ficar chateadas por causa dos escapes. Se isso acontecer, <strong>reafirme a seu filho que isso não importa. Apoie e encoraje</strong> para o sucesso na próxima tentativa.</p>
  </ArticleContainer>
)

export default DesfraldeArticle;
