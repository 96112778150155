import ArticleContainer from "../ArticleContainer";

type Props = {
  bannerImage: string
  bannerImageMobile: string
}

const HidronefroseArticle = ({ bannerImage, bannerImageMobile }: Props): JSX.Element => (
  <ArticleContainer
    title="Hidronefrose"
    highlight="condição benigna transitória ou associada a anomalias congênitas do rim e do trato urinário"
    bannerImage={bannerImage}
    bannerImageMobile={bannerImageMobile}
  >
    <div className="row align-items-center">
      <div className="col-sm-8 col-md-9">
        <h2>O que é?</h2>
        <p> A hidronefrose é a dilatação do rim, podendo ser diagnosticada quando o bebê ainda está no útero, depois que nasce ou até mesmo em crianças mais velhas. A hidronefrose fetal é encontrada em cerca de 1 a 5% de todas as gestações por meio da ultrassonografia pré-natal.</p>
        <p> Crianças do sexo masculino são mais acometidas e pode estar presente em um ou nos dois rins.</p>
      </div>
      <div className="col hidden-on-mobile">
        <img src="/assets/image/icon-disease-hidronefrose.svg" alt="Ícone hidronefrose" />
      </div>
    </div>
    <h2>O que causa?</h2>
    <p>A hidronefrose pode ser causada por um bloqueio em qualquer parte do trato urinário, ou seja, rim, ureter, bexiga ou uretra. Dessa forma, a urina não consegue escoar de forma adequada e se acumula a partir do ponto de obstrução, levando à dilatação renal. Outra causa possível é o retorno da urina da bexiga para os rins, chamado de refluxo vesicoureteral. Além disso, em muitas crianças com diagnóstico pré-natal de hidronefrose, a condição se resolve e a causa nunca é conhecida.</p>
    <p>É importante esclarecer que a hidronefrose durante a gravidez não é causada por nada que os pais tenham feito e pode ter causas genéticas ainda não completamente conhecidas.</p>
    <p>Em crianças maiores, a hidronefrose pode ser causada por uma série de doenças, incluindo cálculos renais.</p>
    <h2>Quais os sintomas?</h2>
    <p> A maioria dos bebês com hidronefrose não apresenta sintomas. Crianças mais velhas também podem não apresentar sintomas, se a hidronefrose for leve ou moderada. Em casos moderados a graves, podem surgir sintomas como dor abdominal e sangue na urina.</p>
    <p> A infecção do trato urinário pode acontecer devido retenção de urina e, entre outros sintomas, se manifesta com:</p>
    <ul>
      <li>Dor para urinar</li>
      <li>Urina turva</li>
      <li>Febre</li>
      <li>Dor lombar</li>
      <li>Vômitos  </li>
      <li>Aumento da frequência urinária  </li>
    </ul>
    <p>Lembrando que em bebês, a infecção do trato urinário, muitas vezes, se apresenta com sintomas inespecíficos como irritabilidade, recusa alimentar, baixo ganho de peso, dentro outros.</p>
    <h2>Qual o tratamento?</h2>
    <p>Quando detectada durante a gravidez, um plano de cuidados para acompanhamento e tratamento é realizado de acordo com os achados na ultrassonografia obstétrica. A intervenção cirúrgica no pré-natal quase nunca é necessária, porque muitas vezes traz mais riscos que benefícios. Essa decisão é feita em conjunto com o urologista pediátrico.</p>
    <p>Após o nascimento, nos casos leves, é feito o acompanhamento com exames de imagem. Os resultados vão determinar quando e se outras ações precisam ser tomadas.</p>
    <p>Nos casos com dilatação mais importante, além de exames de imagem mais frequentes, pode ser necessário o uso de antibiótico em dose baixa para prevenir as infecções do trato urinário. O tipo de antibiótico que seu filho receberá vai depender da idade, peso e alergias.</p>
    <p>Dessa forma, é necessária a monitorização por meses ou até mesmo anos para ver se a dilatação diminui ou se aparecem outros sintomas associados. A necessidade de cirurgia vai depender da gravidade e da repercussão da dilatação renal e é analisada individualmente.</p>
    <p>Em geral, o tratamento do seu filho vai depender da gravidade da sua condição. Como muitas vezes pode se resolver por conta própria, é preciso cautela para identificar aqueles casos mais graves e evitar tratamento desnecessário. </p>
  </ArticleContainer>
)

export default HidronefroseArticle;
