import MetadataComponent from 'components/MetaData';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollToArticle } from 'utils/scroll-to-article.utils';
import AtuacaoArticle from './Sections/Atuacao';
import ContatoArticle from './Sections/Contato';
import CoverArticle from './Sections/Cover';
import NefrologiaArticle from './Sections/Nefrologia';
import RanielyArticle from './Sections/Raniely';

const HomePage = (): JSX.Element => {

  const { hash } = useLocation();

  useEffect(() => {
    setTimeout(() => scrollToArticle(hash.replace('#', '')), 10);
  }, [hash]);

  return (
    <>
      <MetadataComponent
        title="Dra. Raniely – Nefrologia Pediátrica"
        description="Dra. Raniely é médica especialista na área da Nefrologia Pediátrica. Saiba mais, entre em contato ou agende uma consulta."
      />
      <h1 style={{opacity: 0, position: 'absolute', pointerEvents: 'none'}}>Dra. Raniely – Nefrologia Pediátrica</h1>
      <CoverArticle />
      <RanielyArticle />
      <NefrologiaArticle />
      <AtuacaoArticle />
      <ContatoArticle />
    </>
  );
}

export default HomePage;
