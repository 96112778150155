
import { Helmet } from 'react-helmet';

interface Props {
  title: string
  description: string
  addKeywords?: string
  path?: string
}

const MetadataComponent = ({ title, description, addKeywords, path }: Props): JSX.Element => {
  return <Helmet>
    <title>{title}</title>
    <meta name="language" content="pt-BR" />
    <meta name="description" content={description} />
    <meta name="keywords" content={`Nefrologia, pediatria, bebê, criança, infecção, urina, rins, Raniely, Rani, Medicina infantil, ${addKeywords}`} />
    <meta name="author" content="Kelvin Marques Web Dev" />

    <meta property="og:type" content="website" />
    <meta property="og:url" content={`www.drarani.com.br${path}`} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />

    <meta property="article:author" content="Raniely Schmidt" />

    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="@draraninefroped" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
  </Helmet>
}

export default MetadataComponent;