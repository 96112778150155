import MetadataComponent from 'components/MetaData';
import styles from './styles.module.scss';

type Props = {
  title: string
  highlight: JSX.Element | string
  bannerImage?: string
  bannerImageMobile?: string
  children: React.ReactNode
}

const ArticleContainer = ({ title, highlight, bannerImage, bannerImageMobile, children }: Props): JSX.Element => {

  return <>
    <MetadataComponent
      title={`Dra. Raniely | ${title}`}
      description={`${title}: ${highlight}`}
      addKeywords={`doença, ${title}`}
    />
    {bannerImage && <>
      <section className={styles.banner} style={{ backgroundImage: `url("${bannerImage}")` }}></section>
      <section className={styles.bannerMobile} style={{ backgroundImage: `url("${bannerImageMobile || bannerImage}")` }}></section>
    </>}
    <section className={styles.content}>
      <div className="container">
        <div className={styles.titleBox}>
          <h1>{title}</h1>
          <p>{highlight}</p>
        </div>
        {children}
      </div>
    </section>
  </>

}

export default ArticleContainer;
