import classNames from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carousel.scss';
import styles from './styles.module.scss';

// Ao mudar o valor de _sliderInterval, mudar também em styles.scss
const SLIDER_INTERVAL = 8000;

const COVER_DATA: CoverData[] = [
    {
        text: <><strong>Pequenos rins</strong><br /> merecem grandes cuidados</>,
        background: 'slide-1'
    },
    {
        text: <><strong>Atendimento humanizado</strong><br /> às crianças e seus familiares <img src="/assets/image/setas-banner.svg" alt="Setas banner" /></>,
        background: 'slide-2'
    },
    {
        text: <><strong>A prevenção da doença renal</strong><br /> começa na infância</>,
        background: 'slide-3'
    },
    {
        text: <><img src="/assets/image/setas-banner-2.svg" alt="Setas banner" /> A criança não é um adulto<br /> em miniatura e apresenta <br /><strong>problemas e necessidades<br /> próprias da sua idade </strong></>,
        background: 'slide-4'
    }
]

interface CoverData {
    text: JSX.Element,
    background: string
}

const CoverArticle = (): JSX.Element => {
    return (
        <article id="cover" className={styles.article}>
            <Carousel showArrows={false} showThumbs={false} showStatus={false} infiniteLoop interval={SLIDER_INTERVAL} stopOnHover={false} autoPlay >
                {COVER_DATA.map((data, i) => (
                    <div key={i} className={classNames(styles.slide, styles[`slide${i + 1}`])}>
                        <div className="slide-bg" style={{ backgroundImage: `url("/assets/image/cover/${data.background}.jpg")` }}>
                            <div className={styles.boxTexto}>
                                {data.text}
                            </div>
                        </div>
                        <div className="slide-bg slide-mobile" style={{ backgroundImage: `url("/assets/image/cover/${data.background}-mobile.jpg")` }}>
                            <div className={styles.boxTexto}>
                                {data.text}
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
        </article>
    );
}

export default CoverArticle;
