export interface MenuItem {
    title: string;
    link: string;
    children?: MenuItem[];
}

export const menuAtuacaoConst: MenuItem[] = [
    { link: '/atuacao/hidronefrose', title: 'Hidronefrose' },
    { link: '/atuacao/malformacoes', title: 'Malformações renais congênitas' },
    { link: '/atuacao/infeccao', title: 'Infecção urinária' },
    { link: '/atuacao/litiase', title: 'Litíase renal (pedra nos rins)' },
    { link: '/atuacao/hipertensao', title: 'Hipertensão arterial' },
    { link: '/atuacao/desfralde', title: 'Desfralde' },
    { link: '/atuacao/enurese', title: 'Distúrbio miccional e enurese (xixi na cama)' },
    { link: '/atuacao/glumerulopatias', title: 'Síndrome Nefrótica e Nefrítica' }
]

export const menuConst: MenuItem[] = [
    { title: 'Drª Raniely', link: '/#dra-raniely' },
    { title: 'Nefrologia Pediátrica', link: '/#nefrologia-pediatrica' },
    { title: 'Atuação', link: '/#atuacao', children: menuAtuacaoConst },
    { title: 'Contato', link: '/#contato' }
]