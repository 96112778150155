import ArticleContainer from "../ArticleContainer";

type Props = {
  bannerImage: string
  bannerImageMobile: string
}

const LitiaseArticle = ({ bannerImage, bannerImageMobile }: Props): JSX.Element => (
  <ArticleContainer
    title="Litíase Renal (Pedra nos Rins)"
    highlight="os cálculos podem estar presentes por muito tempo sem causar sintomas"
    bannerImage={bannerImage}
    bannerImageMobile={bannerImageMobile}
  >
    <div className="row align-items-center">
      <div className="col-sm-5 col-md-6">
        <p>Você sabia que o xixi contém muitos minerais e sais dissolvidos? Quando a urina do seu filho tem altos níveis desses minerais e sais, eles podem formar pedras nos rins. Outra causa possível é a redução de algumas substâncias também presentes da urina que ajudam a impedir a formação dos cristais.</p>
        <p>Embora as pedras nos rins não sejam comuns em crianças, elas têm aumentado nos últimos anos e podem ser formadas em qualquer ponto do trato urinário: rim, ureter (tubo que conecta o rim à bexiga) ou bexiga.</p>
        <h2>Quais os tipos de pedras?</h2>
        <p>Existem diversos tipos, mas os mais comuns são as pedras de oxalato de cálcio. Com menos frequência, as pedras são formadas por ácido úrico (produto residual da ingestão de certos alimentos e bebidas), cistina (um aminoácido) e outros.</p>
        <h2>O que favorece a formação dos cálculos?</h2>
        <p>Existem vários fatores de risco, como:</p>
        <ul><li>Tendência familiar para formação de pedras</li>
          <li>Dieta rica em sal, carne e alimentos processados e pobre em vegetais e frutas</li>
          <li>Baixa produção de urina por não beber água adequadamente</li>
          <li>Uso de alguns medicamentos, como topiramato ou furosemida</li>
          <li>Doenças específicas como cistinúria e hiperoxalúria</li>
        </ul>
      </div>
      <div className="col hidden-on-mobile">
        <img src="/assets/image/pedra-nos-rins.png" alt="Ilustração de pedra nos rins" />
      </div>
    </div>
    <h2>Quais os sinais e sintomas?</h2>
    <p>Os cálculos podem estar presentes por muito tempo sem causar sintomas ou podem se manifestar com: </p>
    <ul>
      <li>Náuseas e vômitos</li>
      <li>Sangue no xixi</li>
      <li>Queimação ou dor para fazer xixi</li>
      <li>Infecção do trato urinário</li>
      <li>Dor na virilha</li>
      <li>E outros</li>
    </ul>
    <p>É importante lembrar que as pedras nos rins afetam crianças de maneiras diferentes. As crianças pequenas, em particular, podem apresentar sintomas vagos que podem tornar o diagnóstico desafiador. </p>
    <h2>Como é feito o diagnóstico?</h2>
    <p>Através da história médica, exame físico e exames laboratoriais. Além disso, um exame de imagem como radiografia, ultrassom ou tomografia computadorizada é necessário para confirmar a presença, mostrar o tamanho e a localização do cálculo no trato urinário da criança. Isso ajudará na decisão quanto ao melhor tratamento.</p>
    <p>Inicialmente, nas crianças, o exame preferido é o ultrassom porque é fácil de ser feito, pode detectar a maioria dos cálculos e não usa radiação. A tomografia pode mostrar cálculos bem pequenos, mas tem o inconveniente de expor a criança à radiação.</p>
    <h2>Qual o tratamento?</h2>
    <p>O tratamento das pedras nos rins depende do tamanho, de sua composição e se estão causando sintomas ou bloqueando o trato urinário. É provável que pequenas pedras passem sozinhas sem tratamento, mas geralmente exigem o controle da dor e o incentivo à ingestão de muitos líquidos extras para ajudar na passagem da pedra. Cálculos maiores ou que bloqueiam o fluxo de urina podem exigir cirurgia ou hospitalização. </p>
    <p>Toda criança com cálculo renal precisa ser acompanhada por um nefrologista pediátrico, para investigar o motivo da formação da pedra e, assim, implementar medidas necessárias para reduzir o risco de desenvolver novos cálculos.</p>
    <p><strong>O melhor tratamento é a prevenção!</strong></p>
    <div className="row align-items-center">
      <div className="col-sm-9 col-md-10">
        <h2>Como prevenir?</h2>
        <p>Você pode ajudar seu filho a reduzir as chances de desenvolver pedra nos rins, com algumas práticas:</p>
        <ul>
          <li>Reduza o sal da dieta</li>
          <li>Ofereça mais frutas e vegetais, especialmente frutas cítricas</li>
          <li>Reduza alimentos industrializados como refrigerantes e fast-food</li>
          <li>Mantenha o peso do seu filho adequado</li>
          <li>Estimule a ingestão de água ao longo do dia</li>
          <li>Ofereça quantidade adequada de cálcio na alimentação, sem restringir</li>
        </ul>
      </div>
      <div className="col hidden-on-mobile">
        <img src="/assets/image/icon-disease-litiase.svg" alt="Ícone litíase" />
      </div>
    </div>
  </ArticleContainer>
)
export default LitiaseArticle;
