import classNames from "classnames";
import styles from './styles.module.scss';

export type Icon = 'menu' |
'cancel' |
'arroba' |
'phone' |
'pin' |
'instagram' |
'whatsapp' |
'facebook-squared' |
'youtube-play' |
'linkedin-squared' |
'twitter-squared'

interface IconProps {
    icon: Icon,
    className?: string,
    noMargin?: boolean
}

const IconComponent = ({icon, className, noMargin = false}: IconProps): JSX.Element => <i className={classNames(`icon-${icon}`, className, {[styles.noMargin]: noMargin})}></i>;

export default IconComponent;
