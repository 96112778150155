import { withFormik } from "formik";
import * as Yup from "yup";

const schema = Yup.object().shape({

  nome: Yup.string()
    .required("Informe seu nome")
    .min(2, "Nome muito curto")
    .max(100, "O nome deve conter no máximo 100 caracteres"),

  assunto: Yup.string().required("Selecione um assunto"),

  email: Yup.string()
  .required("Informe seu email")
  .email("Informe um email válido"),

  telefone: Yup.string()
    .required("Informe seu telefone fixo ou móvel")
    .min(10, "Informe um telefone fixo ou móvel válido")
    .transform(value => value.replace(/[^\d]/g, '')),

  mensagem: Yup.string()
    .required("Digite uma mensagem")
    .min(20, "A mensagem deve conter no minimo 20 caracteres")
    .max(1000, "A mensagem deve conter no máximo 1000 caracteres"),
});


export const contactWithFormik = withFormik({
  enableReinitialize: true,
  
  mapPropsToValues: () => contactData,
  mapPropsToErrors: () => contactData,
  mapPropsToTouched: () => contactData,
  mapPropsToStatus: () => ({
    message: "",
    severity: "",
    open: false,
    sucess: false,
  }),
  
  validateOnMount: false,
  initialErrors: true,
  initialTouched: true,
  validateOnChange: true,
  validateOnBlur: true,
  validationSchema: schema
});

export const contactData = {
  
  nome: "",
  assunto: "",
  email: "",
  telefone: "",
  mensagem: "",
  
};
