import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface ErrorPageProps {
  status: number;
}

const ErrorPage = (props: ErrorPageProps): JSX.Element => {

  let statusText = useMemo(() => {
    if (props.status === 404) { return 'Página não encontrada.' }
    else if (props.status >= 500 && props.status < 600) { return 'Erro interno no servidor.' }
    else { return 'Erro ao acessar a página.' }
  }, [props.status]);

  return <>
    <div className="container" style={{ minHeight: 'inherit' }}>
      <div className={styles.errorPage} style={{ backgroundImage: 'url("/assets/image/art-rocket-crash.svg")' }}>
        <div>
          <p className={styles.title}>{props.status}</p>
          <p>{statusText}</p>
          <p><Link to="/" className={styles.linkBack}>Voltar para a página inicial</Link></p>
        </div>
      </div>
    </div>
  </>;
}

export default ErrorPage;
