import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HeaderComponent from './components/Header';
import ArticlePage from './pages/Article';
import ErrorPage from './pages/Error';
import HomePage from './pages/Home';

const Routes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <HeaderComponent />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/atuacao/:articleName" component={ArticlePage} />
        <Route render={() => <ErrorPage status={404} />} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes;
